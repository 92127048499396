import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

interface INewUserProps {
}

const NewUser: React.FunctionComponent<INewUserProps> = (props) => {
  return (
    <Container>
        <Typography variant='h6' gutterBottom> Ihre Anmeldung war erfolgreich. Sie werden informiert sobald Sie freigeschalten wurden.</Typography>
    </Container>
  );
};

export default NewUser;

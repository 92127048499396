import { Button, Grid, TextField, Typography } from '@mui/material';
import BasicModal from '../Modals/Modal';
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { changeLexofficeAPIkey } from '../../../api/api';
import ControlledModal from '../Modals/ControlledModal';
import { isUser } from '../../../app/auth';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getUserPayload(): Promise<void>;
}


const AddLexofficeApiKey = (props: Props) => {

    const [contactEvent, setEvent] = React.useState('');
    const [changeLexofficeAPI, { isLoading }] = changeLexofficeAPIkey();
    const [apiKey, setAPIkey] = React.useState('');
    const user = useSelector((state: any) => state.auth.user)
    React.useEffect(() => {
        if (isUser(user)) {
            setAPIkey(user.lexofficeAPI);
        }
    }, []);


    const handleAddEvent = async () => {


        try {

            const payload = await changeLexofficeAPI({ lexofficeAPI: apiKey }).unwrap();
            props.getUserPayload()

        } catch (error) {
            console.error('rejected', error);
        }


    }


    return (
        <div>
            <ControlledModal open={props.open} setOpen={props.setOpen}>
                <Typography variant='h6' gutterBottom> Bitte Lexoffice API Key hinzufügen</Typography>
                <TextField
                    id="standard-basic"
                    label="LexOffice API Key"
                    variant='outlined'
                    fullWidth
                    value={apiKey}
                    onChange={(e) => setAPIkey(e.target.value)}
                />
                <Box mt={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth={true}
                        onClick={handleAddEvent}
                    >
                        Hinzufügen
                    </Button>
                </Box>
            </ControlledModal>
        </div>
    );
};

export default AddLexofficeApiKey;
export const getLexofficeEvents = (type:string) => {
    switch (type) {
      case 'contact.created':
        return 'Kontakt erstellt'
      case 'contact.changed':
        return 'Kontakt geändert'
      case 'contact.deleted':
        return 'Kontakt gelöscht'
      case 'credit-note.created':
        return 'Gutschrift erstellt'
      case 'credit-note.deleted':
        return 'Gutschrift gelöscht'
      case 'credit-note.status.changed':
        return 'Gutschriftstatus geändert'
      case 'delivery-note.created':
        return 'Lieferschein erstellt'
      case 'delivery-note.deleted':
        return 'Lieferschein gelöscht'
      case 'down-payment-invoice.created':
        return 'Anzahlungsrechnung erstellt'
      case 'down-payment-invoice.deleted':
        return 'Anzahlungsrechnung gelöscht'
      case 'down-payment-invoice.status.changed':
        return 'Anzahlungsrechnungstatus geändert'
      case 'dunning.created':
        return 'Mahnung erstellt'
      case 'dunning.deleted':
        return 'Mahnung gelöscht'
      case 'invoice.created':
        return 'Rechnung erstellt'
      case 'invoice.deleted':
        return 'Rechnung gelöscht'
      case 'invoice.status.changed':
        return 'Rechnungsstatus geändert'
      case 'order-confirmation.created':
        return 'Bestellbestätigung erstellt'
      case 'order-confirmation.deleted':
        return 'Bestellbestätigung gelöscht'
      case 'order-confirmation.status.changed':
        return 'Bestellbestätigungsstatus geändert'
      default:
        return 'Unbekanntes Event'

    }
  }


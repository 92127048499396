import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DashboardAccordionProps {
  children: React.ReactNode;
  headline: string;
}

const DashboardAccordion: React.FC<DashboardAccordionProps> = ({ children, headline }) => {
  const theme = useTheme();
  const styles = {
    accordion: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
    }
  };

  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={styles.accordion}
        >
          <Typography>{headline}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default DashboardAccordion;

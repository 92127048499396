import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User } from '../types/User'
import type { RootState } from './store'

export type AuthState = {
  user: User | null | 'LOGOUT' | 'NOT_AUTHENTICATED'
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: null} as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { user } }: PayloadAction<{ user: User}>

    ) => {
      state.user = user 
    },
    logout: (state) => {
      state.user = 'LOGOUT'
    },
    notAuthenticated: (state) => {
      state.user = 'NOT_AUTHENTICATED'
    },
  },
})

export const isUser= (user: User | null | 'LOGOUT' | 'NOT_AUTHENTICATED'): user is User => {
  return user !== null && user !== 'LOGOUT' && user !== 'NOT_AUTHENTICATED'
}

export const { setCredentials } = slice.actions

export const { logout } = slice.actions

export const { notAuthenticated } = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user

import React, {useState} from 'react';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSignUpUser } from '../api/api';
import { useNavigate } from 'react-router-dom';
import GlobalSnackBar, {GlobalSnachBarProps} from '../utils/GlobalSnackbar';

const SignUp = () => {

    const [userName, setuserName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const navigate = useNavigate();
    const [open, setopen] = useState<boolean>(false)
    const [snackbar, setSnackbar] = useState<GlobalSnachBarProps>({open: open, message: '', severity: 'success', setOpen: setopen})

    const [signUpUser, { isLoading }] =  useSignUpUser();


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
         
        try {
            const payload = await signUpUser({userName, email, password}).unwrap();
            navigate('/newuser')
             
          } catch (error:any) {
            console.error('rejected', error);
            setSnackbar({open: true, message: error.data.message, severity: 'error', setOpen: setopen})
            setopen(true)
          }
    }


    return (
        <div>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Registrieren
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
              value={userName}
              onChange={(e) => setuserName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Addresse"
              name="email"
              autoComplete="email"
              autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              
            >
             Registrieren
            </Button>
            {open && <GlobalSnackBar {...snackbar} />}
            <Grid container>
              <Grid item xs>
                <Link href="/auth/forgotpassword" variant="body2">
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/login" variant="body2">
                  {"Haben Sie schon einen Account?"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
        </div>
    );
};

export default SignUp;
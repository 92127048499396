import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useGetLexofficeSubscription, getCurrentUser, deleteLexOfficeSubscription } from '../../../api/api';
import { useSelector } from 'react-redux';
import {GridColDef,} from '@mui/x-data-grid';
import AddLexoffice from './AddLexoffice';
import { useCookies } from 'react-cookie';
import AddLexofficeApiKey from './AddLexofficeApiKey';
import { getLexofficeEvents } from '../../../types/LexfficeEventstoGerman';
import Structure from '../Structure';


export type CurrentScreen = 'LOADING' | 'INSERTAPIKEY' | 'DATA'


const LexOffice = () => {


  const [get, { isLoading: isLoadingGet }] = useGetLexofficeSubscription();
  const [getUser, { isLoading: isLoadingUser }] = getCurrentUser();
  const [deleteLexOfficeSub, { isLoading: isLoadingDelete }] = deleteLexOfficeSubscription();

  const [data, setdata] = useState<any[]>([])
  const [currentScreen, setcurrentScreen] = useState<CurrentScreen>('LOADING');
  const [open, setopen] = useState<boolean>(false)


  useEffect(() => {
    getData()
  }, [])

  async function getData() {
    try {
      const payload = await get({}).unwrap();
       
      let germanData = payload.map((item: any) => {
        const type = item.eventType
        const germenType = getLexofficeEvents(type)
         
        return {
          ...item,
          name: germenType,
          id: item.subscriptionId
        }

      })
      setdata(germanData)
    } catch (error) {
      console.error('rejected', error);
    }
  }

  async function getUserPayload() {
    try {
      const payload = await getUser({}).unwrap();
      if (payload.lexofficeAPI) {
        setopen(false)
        setcurrentScreen('DATA')
      } else {
        setcurrentScreen('INSERTAPIKEY')
      }
    } catch (error) {
      console.error('rejected', error);
    }
  }
  useEffect(() => {

    getUserPayload()

  }, [])

  const deleteSubscription = async (id: any) => {
    try {
      const payload = await deleteLexOfficeSub({ subscription: id }).unwrap();
       
      const newData = data.filter((item) => item.subscriptionId !== id)
      setdata(newData)
    } catch (error) {
      console.error('rejected', error);
    }
  }



  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Status',
      width: 180,
      editable: true,
    },
    {
      field: 'callbackUrl',
      headerName: 'Url',
      width: 180,
      editable: true,
    },
    {
      field: "löschen",
      headerName: "Bearbeiten",
      width: 150,
      sortable: false,
      renderCell: (params) => {

        return <Button variant='contained' onClick={() => deleteSubscription(params.id)}>Löschen</Button>;
      }
    },
  ];



  return (

    <Structure data={data} 
    currentScreen={currentScreen} 
    columns={columns}
    AddKey = {<AddLexofficeApiKey 
                  open={open} setOpen={setopen} 
                  getUserPayload={getUserPayload} />} 
    AddSubscription={<AddLexoffice
                  getData={()=>getData()}
      />}/>

  );
};

export default LexOffice;


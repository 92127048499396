import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Box from '@mui/material/Box';
import { getCurrentUser, updateCurrentUser } from '../api/api';
import Alert from '@mui/material/Alert';


interface IUserSettingsProps {
  toggleModify: any,
}

const UserSettings: React.FunctionComponent<IUserSettingsProps> = (props) => {
    const [user, setUser] = React.useState({}) as any;
    const [getUser, { isLoading: isLoadingUser }] =  getCurrentUser();
    const [changeCurrentUser, { isLoading: isLoadingUpdateUser }] =  updateCurrentUser();
    const [message, setMessage] = React.useState('');


    async function getUserPayload () {
        try {
           const payload = await getUser({}).unwrap();
            
           const currentUser = {
                username: payload.username,
                firstname: payload.firstname,
                lastname: payload.lastname,
                email: payload.email,
           }

              
           setUser(currentUser)

        }catch (error) {
            console.error('rejected', error);
        }
    }

    React.useEffect(() => {
        getUserPayload()
    }, [])

    React.useEffect(() => {
        if(message === '') return;
        setTimeout(() => {
            setMessage('')
        }, 6000)
    }, [message])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser((prevUser:any) => ({ ...prevUser, [name]: value }));
      };
    
      const handleSubmit =  async (event: React.FormEvent<HTMLFormElement>) =>  {
        event.preventDefault();
         
        try {
            const payload = await changeCurrentUser({user: user}).unwrap();
             
            setMessage('Benutzer wurde erfolgreich geändert')
          } catch (error) {
            console.error('rejected', error);
          }
      };
        

        return (
            <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
             <Grid container direction={'row'} spacing={4}>
          <Grid item>
            <Typography variant="h5" align="center">
              Benutzer
            </Typography>
            </Grid>
            <Grid item>
              <BackspaceIcon onClick={props.toggleModify} sx={{cursor: 'pointer'}}/>
              </Grid>
        </Grid>
            <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
              <TextField
                label="Username"
                name="username"
                margin="normal"
                fullWidth
                value={user?.username? user.username:''}
                onChange={handleChange}
              />
              <TextField
                label="Vorname"
                name="firstname"
                margin="normal"
                fullWidth
                value={user?.firstname? user.firstname:''}
                onChange={handleChange}
              />
              <TextField
                label="Nachname"
                name="lastname"
                margin="normal"
                fullWidth
                value={user?.lastname? user.lastname:''}
                onChange={handleChange}
              />
              <TextField
                type="email"
                label="Email"
                margin="normal"
                name="email"
                fullWidth
                value={user?.email?user.email:''}
                onChange={handleChange}
              />
              <TextField
                type="password"
                margin="normal"
                label="Passwort"
                name="password"
                fullWidth
                value={user?.password?user.password:''}
                onChange={handleChange}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
              >
                Speichern
              </Button>
              <Box mt={2}>
              {message&&<Alert severity="success">{message}</Alert>}
              </Box>
              </Box>
        </Box>
          </Container>
        );
      
  
};

export default UserSettings;

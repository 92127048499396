import { Button, TextField, Typography } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import { changeLexofficeAPIkey } from '../../../api/api';
import ControlledModal from '../Modals/ControlledModal';
import { RootState } from '../../../app/store';
import { useSelector } from 'react-redux';
import { isUser } from '../../../app/auth';

interface Props {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    getUserPayload(): Promise<void>;
}


const AddSevKey = (props: Props) => {
    const [changeLexofficeAPI] = changeLexofficeAPIkey();
    const user = useSelector((state: RootState) => state.auth.user)
    const [apiKey, setAPIkey] = React.useState('');
    React.useEffect(() => {
        if (isUser(user)) {
            setAPIkey(user.sevDeskAPI);
        }
    }, []);


    const handleAddEvent = async () => {


        try {

            await changeLexofficeAPI({ sevDeskAPI: apiKey }).unwrap();
            props.getUserPayload()
            props.setOpen(false)

        } catch (error) {
            console.error('rejected', error);
        }


    }


    return (
        <div>
            <ControlledModal open={props.open} setOpen={props.setOpen}>
                <Typography variant='h6' gutterBottom> Bitte SevDesk API Key hinzufügen</Typography>
                <TextField
                    id="standard-basic"
                    label="SevDesk API Key"
                    variant='outlined'
                    fullWidth
                    value={apiKey}
                    onChange={(e) => setAPIkey(e.target.value)}
                />
                <Box mt={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth={true}
                        onClick={handleAddEvent}
                    >
                        Hinzufügen
                    </Button>
                </Box>
            </ControlledModal>
        </div>
    );
};

export default AddSevKey;
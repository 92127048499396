import { Button, Grid, TextField } from '@mui/material';
import BasicModal from '../Modals/Modal';
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useCreatSevDeskSupscription } from '../../../api/api';


interface Props {
    getData(): Promise<void>;
}

type EventType = "AN" | "AB" | "LI" | "Contact" | "Invoice" | ""

interface EventTypeInteface {
    type: EventType,
    name: string,
}

type EventStatus = 'Draft' | 'Open' | 'Payed' | 'All' | 'Delivered' | 'Rejected' | 'Accepted' | 'Partially invoiced' | 'Invoiced'| "";


const AddSevSupscription = (props: Props) => {

    const [type, setType] = React.useState<EventType>("");
    const [eventStatus, setEventStatus] = React.useState<EventStatus>('')
    const [allPossibleEvents, setAllPossibleEvents] = React.useState<EventStatus[]>([])
    const [url, setUrl] = React.useState('');
    const [open, setopen] = React.useState(false)

    const [createSevDeskSupscription] = useCreatSevDeskSupscription();


    const handleTypeChange = (event: SelectChangeEvent) => {
        const value = event.target.value as EventType
        setPossibleStatuts(value)
        setType(value)
    };

    const handleEventChange = (event: SelectChangeEvent) => {
        const value = event.target.value as EventStatus  
        setEventStatus(value)
    };


    const handleAddEvent = async () => {
        
        const supscritption = {
            event: eventStatus,
            eventType: type,
            callBackUrl:url
        }

         
        try {
            await createSevDeskSupscription(supscritption).unwrap();
            props.getData()
            setopen(false)
        } catch (error) {
            console.error('rejected', error);
        }


    }

    const setPossibleStatuts = (type: EventType) => {
        let status:EventStatus[] = [];
        switch (type){
            case 'Contact':
                status.push("All")
            break;
            case 'Invoice':
                status.push(
                    "Draft",
                    "Open",
                    "Payed"
                )
                break;
            case 'AB':
            case 'AN':
            case 'LI':
                status.push("Draft",
                    "Delivered",
                    "Rejected",
                    "Accepted",
                    "Partially invoiced",
                    "Invoiced"
                     )
            break;
        }
        setAllPossibleEvents(status);
    }

    const eventType: EventTypeInteface[] = [
        {
            type: "Invoice",
            name: "Rechnung",
        },
        {
            type: "Contact",
            name: "Kontakt",
        },
        {
            type: "AN",
            name: "Angebot"
        },
        {
            type: "AB",
            name: "Auftragsbestätigung"
        },
        {
            type: "LI",
            name: "Lieferscehin"
        }
    ]



    return (
        <div>
            <BasicModal open={open} setOpen={setopen}>
                <Grid container direction={'row'} spacing={4}>
                    <Grid item xs={3} >
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Event Typ</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={type}
                                    
                                    label="Age"
                                    onChange={handleTypeChange}
                                   
                                >
                                    {eventType.map(event => {
                                        return <MenuItem value={event.type}>{event.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={3} >
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Event</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={eventStatus}
                                    label="Age"
                                    onChange={handleEventChange}
                                >
                                    {allPossibleEvents.map(event => {
                                        return <MenuItem value={event}>{event}</MenuItem>
                                    })}

                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id="standard-basic"
                            label="Make URL"
                            variant='outlined'
                            fullWidth
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth={true}
                        onClick={handleAddEvent}
                    >
                        Hinzufügen
                    </Button>
                </Box>
            </BasicModal>
        </div>
    );
};

export default AddSevSupscription;



import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom';
import useToggle from '../utils/useToggle';
import { useSelector } from 'react-redux';
import { RootState } from '../app/store';
import { useDispatch } from 'react-redux';
import { logout } from '../app/auth';
import logo from '../assets/Logo.png';
import { useTheme } from '@mui/material';
import { User } from '../types/User';
import { getCurrentUser } from '../api/api';
import { setCredentials } from '../app/auth';

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

interface NavItem {
  name: string;
  function: () => void;
}

const drawerWidth = 240;


export default function Header(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate()

  const [navItems, setnavItems] = React.useState<NavItem[]>([])
  const user = useSelector((state: RootState) => state.auth.user)
  const dispatch = useDispatch()

  const theme = useTheme();
  const [getUser, { isLoading: isLoadingUser }] =  getCurrentUser();
  

  React.useEffect(() => {
    setnavItems(getNavItems())
    console.log(user)
  }, [user])




  const getNavItems = (): NavItem[] => {
    const token = Cookies.get('x-access-token')
    let returnArray: NavItem[] = []
    if (token) {
      returnArray = [{
        name: 'Dashboard',
        function: () => navigate('/dashboard')
      }, 
      {
        name: 'Logout',
        function: () => {
          Cookies.remove('x-access-token')
          navigate('/')
          dispatch(logout())
        },
      },
      ]
    } else {
      returnArray = [{
        name: 'Login',
        function: () => navigate('/')
      }]
    }
   
    if(user && (user as User).roles?.includes('ROLE_ADMIN')) {
      returnArray.unshift({
        name: 'Admin',
        function: () => navigate('/admin')
      })
    }
    return returnArray
  }


  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider />
      <List>
        {navItems.map((item: NavItem) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton onClick={() => item.function()} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>

      <CssBaseline />
      <AppBar component="nav"
      sx={{backgroundColor: theme.palette.primary.main, color: '#fff'}}
      >
        <Container>
          <Toolbar>
            <img height={40} src={logo} alt="logo" />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ ml:2, mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              ml={2}
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              CoConnecter
            </Typography>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item: NavItem) => (
                <Button key={item.name} onClick={() => item.function()} sx={{ color: '#fff' }}>
                  {item.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>

      </Box>

    </Box>
  )
}
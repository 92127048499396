import { Card, CardContent, Container, Grid, Box } from '@mui/material';
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LexOffice from '../ConnectedServices/Lexoffice/Lexoffice';
import useTheme from '@mui/material/styles/useTheme';
import UserInfos from '../UserInfos';
import  useToggle  from '../../utils/useToggle';
import UserSettings from '../Usersettings';
import Sevdesk from '../ConnectedServices/SevDesk/SevDesk';
import DashboardAccordion from './DashboardAccordions';
import { getCurrentUser } from '../../api/api';

interface IDashboardProps {
}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
    const [modifyUser, setModifyUser] = useToggle(false);
    const [getUser, { isLoading: isLoadingUser }] =  getCurrentUser();

    React.useEffect(() => {
        getUser({}).unwrap()
    },[])


    return (
        <Container>
            <Grid container direction={'row'} spacing={2}>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                           {!modifyUser ? <UserInfos toggleModify={setModifyUser} />
                            : <UserSettings toggleModify={setModifyUser}/>   
                        }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardAccordion headline="Lexoffice">
                        <LexOffice />
                    </DashboardAccordion>
                    <Box mt={4}>
                    <DashboardAccordion headline="SevDesk">
                        <Sevdesk/>
                    </DashboardAccordion>
                    </Box>
                </Grid>
                <Grid item xs={12} md= {6}>
                </Grid>
            </Grid>
        </Container>
    )

};

export default Dashboard;


import * as React from 'react';
import {
    Box,
    CircularProgress,
} from '@mui/material'
import {
    DataGrid,
    GridColDef
} from '@mui/x-data-grid'

interface IStructureProps {
    currentScreen: string,
    data:any,
    AddKey: React.ReactNode,
    AddSubscription: React.ReactNode,
    columns:GridColDef[],
}

const Structure: React.FunctionComponent<IStructureProps> = ({currentScreen, data, AddKey, AddSubscription, columns}) => {
    React.useEffect(()=>{
         
         
    },[data])

    const ShowRightScreen= () => {
       switch (currentScreen) {
          case 'LOADING':
            return (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            )
          case 'INSERTAPIKEY':
            return (<>
              <DataTable />
            </>
            )
          case 'DATA':
            return<><DataTable /></> 
          default:
           return <></>
        }
      }
    
      const DataTable = () => {
        return (
          <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} mb={2}>
              {AddKey}
            </Box>
            <DataGrid
              autoHeight
              rows={data}
              columns={columns}
              pageSize={5}
              getRowId={(row) => row.id}
              rowsPerPageOptions={[5]}
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            //   onSelectionModelChange={(ids) => {
            //     const selectedIDs = new Set(ids);
            //     const selectedRowData = data.filter((row) =>
            //       selectedIDs.has(row?.subscriptionId.toString())
            //     );
            //     setcurrenSelected(selectedRowData)
            //   }}
            />
            <Box display={'flex'} justifyContent='center' mt={2}>
              {AddSubscription}
    
            </Box>
          </div>
        );
      }

  return(
    <ShowRightScreen/>
  ) ;
};

export default Structure;

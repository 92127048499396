import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { getCurrentUser, updateCurrentUser } from '../api/api';
import Alert from '@mui/material/Alert';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';


interface IUserSettingsProps {
  toggleModify: any,
}

const UserInfos: React.FunctionComponent<IUserSettingsProps> = (props) => {
    const [user, setUser] = React.useState({}) as any;
    const [getUser, { isLoading: isLoadingUser }] =  getCurrentUser();
    const [changeCurrentUser, { isLoading: isLoadingUpdateUser }] =  updateCurrentUser();
    const [message, setMessage] = React.useState('');


    async function getUserPayload () {
        try {
           const payload = await getUser({}).unwrap();
            
           const currentUser = {
                username: payload.username,
                firstname: payload.firstname,
                lastname: payload.lastname,
                email: payload.email,
           }

              
           setUser(currentUser)

        }catch (error) {
            console.error('rejected', error);
        }
    }

    React.useEffect(() => {
        getUserPayload()
    }, [])

    React.useEffect(() => {
        if(message === '') return;
        setTimeout(() => {
            setMessage('')
        }, 6000)
    }, [message])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser((prevUser:any) => ({ ...prevUser, [name]: value }));
      };
    
      const handleSubmit =  async (event: React.FormEvent<HTMLFormElement>) =>  {
        event.preventDefault();
         
        try {
            const payload = await changeCurrentUser({user: user}).unwrap();
             
            setMessage('Benutzer wurde erfolgreich geändert')
          } catch (error) {
            console.error('rejected', error);
          }
      };
        
  const firstColumn = 5;
  const secondColumn = 7;

        return (
            <Container component="main" maxWidth="xs">

        <Grid container direction={'row'} spacing={4}>
          <Grid item>
            <Typography variant="h5" align="center">
              Benutzer
            </Typography>
            </Grid>
            <Grid item>
              <EditIcon onClick={props.toggleModify} sx={{cursor: 'pointer'}}/>
              </Grid>
        </Grid>
            <Box  sx={{ mt: 1 }}>
              <Grid container direction={'row'}>
                <Grid item xs={firstColumn}>
                  <Typography variant="h6">
                  Username:
                  </Typography>
                </Grid>
                <Grid item xs={secondColumn}>
              <Typography variant="h6" >
              {user?.username? user.username:''}
              </Typography>
              </Grid>
              <Grid item xs={firstColumn}>
              <Typography variant="h6">
                Name:
              </Typography>
              </Grid>
              <Grid item xs={secondColumn}>
              <Typography variant="h6">
              {user?.firstname? user.firstname:''} {user?.lastname? user.lastname:''}
              </Typography>
              </Grid>
              <Grid item xs={firstColumn}>
                <Typography variant="h6">
                Email:
                </Typography>
              </Grid>
              <Grid item xs={secondColumn}>
              <Typography variant="h6">
              {user?.email?user.email:''}
              </Typography>
              </Grid>
              </Grid>
            </Box>
           
          </Container>
        );
      
  
};

export default UserInfos;

import React, { useEffect, useState } from 'react';
import { sevDeskGetSubscriptions, useDeleteSevDeskSupscrition } from '../../../api/api';
import {
  GridColDef
} from '@mui/x-data-grid'
import {
  Button
} from '@mui/material'
import AddSevKey from './AddSevKey'
import Structure from '../Structure';
import { CurrentScreen } from '../Lexoffice/Lexoffice'
import AddSevSupscription from './AddSevSubscription';


interface ISevdeskProps {
}

const Sevdesk: React.FunctionComponent<ISevdeskProps> = (props) => {
  const [getSevdeskSubscription] = sevDeskGetSubscriptions();
  const [deleteSevdeskSubscription] = useDeleteSevDeskSupscrition();

  const [data, setdata] = useState<any[]>([])
  const [currentScreen, setcurrentScreen] = useState<CurrentScreen>('LOADING');
  const [open, setOpen] = useState<boolean>(false);

  async function getUserPayload() {
    try {
      const payload = await getSevdeskSubscription({}).unwrap()
      let subscriptions:any[] = []
      payload.subscriptions.forEach((subscription:any) => {
        const newSubscription = {
          ...subscription,
          eventName: matchEventType(subscription.eventType)
        }  
        subscriptions.push(newSubscription);
      })
        
      setdata(subscriptions);
       
      setcurrentScreen("DATA")
    } catch (error) {
      console.error('rejected', error);
    }
  }

  const matchEventType = (eventType:string) => {
    switch(eventType){
      case "AN":
        return "Angebot"
      case "AB":
        return "Auftragsbestätigung"
      case "LI":
        return "Lieferschein"
      case "Invoice":
        return "Rechnung"
      case "Contact":
        return "Kontakt"
      default:
        return "Event nicht erkannt"
    }
  }

  async function deleteSubscription(id:string) {
      try{
        const deleteObject = {id: id}
         
      await deleteSevdeskSubscription(deleteObject).unwrap();
         
      } catch (error) {
        console.error('rejected', error)
      }
  }

  useEffect(() => {
    getUserPayload();

  }, [])

  const columns: GridColDef[] = [
    {
      field: 'eventName',
      headerName: 'Event',
      width: 130,
      editable: true,
    },
    {
      field: 'event',
      headerName: 'Status',
      width: 100,
      editable: true,
    },
    {
      field: 'callBackUrl',
      headerName: 'Url',
      width: 170,
      editable: true,
    },
    {
      field: "löschen",
      headerName: "Bearbeiten",
      width: 130,
      sortable: false,
      renderCell: (params) => {

        return <Button variant='contained' onClick={() => deleteSubscription(params.id as string)}>Löschen</Button>;
      }
    },
  ];
  return (
    <Structure
      data={data}
      columns={columns}
      currentScreen={currentScreen}
      AddKey={<AddSevKey
        open={open}
        setOpen={setOpen}
        getUserPayload={() => getUserPayload()}/>}
      AddSubscription={
        <AddSevSupscription
          getData={() => getUserPayload()}
        />}
    />
  );
};

export default Sevdesk;

import * as React from 'react';
import { Container, Typography, Button, Switch } from '@mui/material';
import { getAllUsers, changeUserCoLicence, changeUserMmLicence } from '../api/api';
import { DataGrid, GridColDef, GridValueGetterParams, GridApi, GridRowId} from '@mui/x-data-grid';

interface IAdminViewProps {
}

const AdminView: React.FunctionComponent<IAdminViewProps> = (props) => {

    const [users, setUsers] = React.useState<any[]>([]);
    const [ get, { isLoading: isLoadingGet }] =  getAllUsers();
    const [ coChange, { isLoading: isLoadingChangeCo }] =  changeUserCoLicence();
    const [ mmChange, { isLoading: isLoadingChangeMm }] =  changeUserMmLicence();

    React.useEffect(() => {
        async function getDAta () {
            try {
                const payload = await get({}).unwrap();
                 
                setUsers(payload)
            }catch (error) {
                console.error('rejected', error);
            }
        }
        getDAta()
    }, [])

    const handleCoChange = (event: React.ChangeEvent<HTMLInputElement>, id:GridRowId) => {
        setUsers((prev) => {
            const newUsers = prev.map((user) => {
                if (user.id === id) {
                    return {
                        ...user,
                        coConnecterLicence: event.target.checked,
                    };
                }
                return user;
            });
            return newUsers;
        });
        
        try {
            let change = ~~event.target.checked;
            console.log(change.toString())
            coChange({userId: id, licence: change.toString()}).unwrap();
        }catch (error) {
            console.error('rejected', error);
        }
    }
    const handleMmChange = (event: React.ChangeEvent<HTMLInputElement>, id:GridRowId) => {
        setUsers((prev) => {
            const newUsers = prev.map((user) => {
                if (user.id === id) {
                    return {
                        ...user,
                        syncClientLicence: event.target.checked,
                    };
                }
                return user;
            });
            return newUsers;
        });
        
        try {
            let change = ~~event.target.checked;
            console.log(change.toString())
            mmChange({userId: id, licence: change.toString()}).unwrap();
        }catch (error) {
            console.error('rejected', error);
        }
    }

    const columns: GridColDef[] = [
        {field: 'id', headerName: 'ID', width: 50 },
        {field: 'username', headerName: 'Username', width: 150 },
        {field: 'firstname', headerName: 'Vorname', width: 150 },
        {field: 'lastname', headerName: 'Nachname', width: 150 },
        {field: 'email', headerName: 'Email', width: 150 },
        {
            field: "coConnecterLicence",
            headerName: "CoConnecter Sync",
            sortable: false,
            width: 250,
            renderCell: (params) => {
              const onClick = (e:any) => {
                e.stopPropagation();    
              }
        
              return <Switch checked={Boolean(Number(params.row.coConnecterLicence))} onChange={(evt:any) => handleCoChange(evt, params.id, )} />;
            }
          },
          {
            field: "syncClientLicence",
            headerName: "MemoMeister Sync-Client",
            sortable: false,
            width: 200,
            renderCell: (params) => {
              const onClick = (e:any) => {
                e.stopPropagation();    
              }
              return <Switch checked={Boolean(Number(params.row.syncClientLicence))} onChange={(evt:any) => handleMmChange(evt, params.id)} />;
            }
          },        
      ];

    return (
        <>
            <Container>
                <Typography component="h1" variant="h5" textAlign={'center'} mb={3}>Admin</Typography>
                <DataGrid
                    autoHeight
                    rows={users}
                    columns={columns}
                    pageSize={5}
                    getRowId={(row) => row.id}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </Container>
        </>
    ) ;
};

export default AdminView;

import { Button, Grid, TextField } from '@mui/material';
import BasicModal from '../Modals/Modal';
import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useSelector } from 'react-redux';
import { useUpdateLexofficeSubscription } from '../../../api/api';
import { getLexofficeEvents } from '../../../types/LexfficeEventstoGerman';


interface Props {
    getData(): Promise<void>;
}

const AddLexoffice = (props: Props) => {

    const [contactEvent, setEvent] = React.useState('');
    const [upDateLexoffice, { isLoading }] = useUpdateLexofficeSubscription();
    const [url, setUrl] = React.useState('');
    const [open, setopen] = React.useState(false)
    const handleChange = (event: SelectChangeEvent) => {
        setEvent(event.target.value as string);
    };

    const handleAddEvent = async () => {
        const subscriptions = [
            {
                "event": contactEvent,
                "lexofficeCallbackUrl": url
            }
        ]

         
        try {
            const payload = await upDateLexoffice({ subscriptions }).unwrap();
            props.getData()
            setopen(false)
             
        } catch (error) {
            console.error('rejected', error);
        }


    }

    const events = [
        "contact.created",
        "contact.changed",
        "contact.deleted",
        "credit-note.created",
        "credit-note.deleted",
        "credit-note.status.changed",
        "delivery-note.created",
        "delivery-note.deleted",
        "down-payment-invoice.created",
        "down-payment-invoice.deleted",

        "down-payment-invoice.status.changed",
        "dunning.created",
        "dunning.deleted",
        "invoice.created",
        "invoice.deleted",
        "invoice.status.changed",
        "order-confirmation.created",
        "order-confirmation.deleted",
        "order-confirmation.status.changed",

    ]


    return (
        <div>
            <BasicModal open={open} setOpen={setopen}>
                <Grid container direction={'row'} spacing={4}>
                    <Grid item xs={4} >
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Kontakt</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={contactEvent}
                                    label="Age"
                                    onChange={handleChange}
                                >
                                    {events.map(event => {
                                        return <MenuItem value={event}>{getLexofficeEvents(event)}</MenuItem>
                                    })}
                                    {/* <MenuItem value={'contact.created'}>Kontakt erstellt</MenuItem>
                                    <MenuItem value={'contact.deleted'}>Kontakt gelöscht</MenuItem>
                                    <MenuItem value={'contact.changed'}>Kontakt geändert</MenuItem> */}
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            id="standard-basic"
                            label="Make URL"
                            variant='outlined'
                            fullWidth
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Box mt={3}>
                    <Button
                        variant='contained'
                        color='primary'
                        fullWidth={true}
                        onClick={handleAddEvent}
                    >
                        Hinzufügen
                    </Button>
                </Box>
            </BasicModal>
        </div>
    );
};

export default AddLexoffice;
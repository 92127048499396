import React from 'react';
import './App.css';
import {
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom"
import Login from './components/Login';
import SignUp from './components/SignUp';
import LexOffice from './components/ConnectedServices/Lexoffice/Lexoffice';
import { Box } from '@mui/system';
import Header from './components/Header';
import AdminView from './components/AdminView';
import NewUser from './components/NewUser';
import { notAuthenticated, setCredentials } from './app/auth';
import Dashboard from './components/Dashboard/Dashboard';
import { User } from './types/User';
import { RootState } from './app/store';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AuthState } from './app/auth';
import { JsxElement } from 'typescript';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from './api/api';
import ResetPassword from './components/ResetPassword';
import CreateResetMail from './components/CreateResetEmail';

interface ProtectedRoutePropsProps {
  user: any,
  redirectPath: string,
  children: React.ReactElement,
}

const ProtectedRoute = (
  props: ProtectedRoutePropsProps
) => {
   
  if (props.user=="NOT_AUTHENTICATED") {
    return <Navigate to={props.redirectPath} />;
  }

  return props.children;
};


function App() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getUser, { isLoading: isLoadingUser }] =  getCurrentUser();

  const user = useSelector((state: RootState) => state.auth.user)
   
  React.useEffect(() => {
    async function getUserPayload () {
      try {
        const user = await getUser({}).unwrap();
        if(!user&&user!=="NOT_AUTHENTICATED"){
          dispatch(notAuthenticated())
          return
        } else{
        dispatch(setCredentials({user}))
        }
      } catch (error) {
        dispatch(notAuthenticated())
         
      }
    }
    getUserPayload()
  }, [])

  return (
    user!==null ?
    <>
      <Box mb={12}>
        <Header />
      </Box>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/auth/resetPassword" element={<ResetPassword />} />
        <Route path="/auth/forgotPassword" element={<CreateResetMail />} />


        <Route path="/signup" element={<SignUp />} />
        <Route path='/dashboard' element={
          <ProtectedRoute redirectPath='/login' user={user}>
            <Dashboard />
          </ProtectedRoute>
        } />
        <Route path='/lexoffice' element={<LexOffice />} />
        <Route path='/admin' element={<AdminView />} />
        <Route path='/newuser' element={<NewUser />} />
      </Routes>
    </>:
    <></>
  );
}

export default App;

import React, {useState, useEffect} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSignInUser } from '../api/api';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { setCredentials } from '../app/auth'
import { Navigate, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import GlobalSnackBar, {GlobalSnachBarProps} from '../utils/GlobalSnackbar';
import { ErrorType } from '../types/ErrorType';
import { useCreateResetPasswordMail } from '../api/api';



const CreateResetMail = () => {



  const [email, setEmail] = useState<string>("")
  const [open, setopen] = useState<boolean>(false)
  const [disabled, setdisabled] = useState<boolean>(false)

  const [snackbar, setSnackbar] = useState<GlobalSnachBarProps>({open: open, message: '', severity: 'success', setOpen: setopen})

  const [createResetPasswordMail, { isLoading: isLoadingCreateResetPasswordMail }] = useCreateResetPasswordMail()

  const dispatch = useDispatch()
  const navigate = useNavigate()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
          console.log(email)
          createResetPasswordMail({email}).unwrap()
          setdisabled(true)
          } catch (error) {
            setopen(true)
            const customError = error as ErrorType
            error = customError.data
             
          }
    }

    useEffect(() => {
      if(open){
        setSnackbar({open: open, message: 'invalid Credentials', severity: 'error', setOpen: setopen})
      }
    }, [open])
    

    return (
        <div>
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Email Adresse eingeben
          </Typography>
          {
            disabled ? <Typography component="h1" variant="h6" textAlign={'center'} mt={3} mb={2}>
              Falls die Emailadresse in unserer Datenbank existiert, wird eine Email mit einem Link zum zurücksetzen des Passworts versendet. Der Link
              15 Minuten gültig.
            </Typography>:
            <>
          <Typography component="h1" variant="body1" mt={3} mb={2} textAlign={'center'}>
            Email Adresse eingeben, für die das Passwort zurückgesetzt werden soll
          </Typography>
          </>}
          <Box component="form" onSubmit={handleSubmit}  sx={{ mt: 1 }}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Addresse"
              name="email"
              autoComplete="email"
              autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disabled}
            >
              Zurücksetzen
            </Button>
           {open && <GlobalSnackBar {...snackbar} />}
            <Grid container>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Kein Konto? Jetzt registrieren!"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
        </div>
    );
};

export default CreateResetMail;


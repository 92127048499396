import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export interface GlobalSnachBarProps {
    message: string;
    severity: 'error' | 'info' | 'success' | 'warning';
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function GlobalSnackBar(props: GlobalSnachBarProps) {

    React.useEffect(() => {
    setTimeout(() => {
        props.setOpen(false)
         
    }, 6000)
    }, [])
    
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        props.setOpen(false);
      };

  return (
        props.open?
        <Alert  onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
          {props.message}
        </Alert>
        : <></>

  );
}


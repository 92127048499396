import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSignInUser } from '../api/api';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { setCredentials } from '../app/auth'
import { Navigate, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import GlobalSnackBar, { GlobalSnachBarProps } from '../utils/GlobalSnackbar';
import { ErrorType } from '../types/ErrorType';
import { useResetPassword } from '../api/api';



const ResetPassword = () => {


  const [signInUser, { isLoading }] = useSignInUser();
  const state = useSelector((state: any) => state)
  const [resetPassword, { isLoading: isLoadingResetPassword }] = useResetPassword()
  const [disabled, setdisabled] = useState<boolean>(false)

  const [password, setpassword] = useState<string>("")
  const [cookies, setCookie] = useCookies(['x-access-token']);
  const [open, setopen] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<GlobalSnachBarProps>({ open: open, message: '', severity: 'success', setOpen: setopen })
  const location = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const payload = await resetPassword({ password, token, email }).unwrap();
      setdisabled(true)
    } catch (error) {
      console.log(error)
      setopen(true)
      const customError = error as ErrorType
      error = customError.data

    }
  }

  useEffect(() => {
    if (open) {
      setSnackbar({ open: open, message: 'invalid Credentials', severity: 'error', setOpen: setopen })
    }
  }, [open])


  return (
    <div>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Passwort zurücksetzen
          </Typography>
          {
            disabled && <Typography component="h1" variant="h6" textAlign={'center'} mt={3} mb={2}>
              Das Passwort wurde erfolgreich zurückgesetzt!
            </Typography>
          }
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={disabled}
            >
              Zurücksetzen
            </Button>
            {open && <GlobalSnackBar {...snackbar} />}
            <Grid container>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"Kein Konto? Jetzt registrieren!"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default ResetPassword;

